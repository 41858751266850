import React, { lazy, Suspense, useEffect } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import withAdminLayout from '../../layout/withAdminLayout';
import AuthLayout from '../../container/profile/authentication/Index';

const Home = lazy(() => import('../../container/profile/authentication/overview/Home'));
const Host = lazy(() => import('../../container/profile/authentication/overview/Host'));
const AboutUs = lazy(() => import('../../container/profile/authentication/overview/AboutUs'));
const houseAthlete = lazy(() => import('../../container/profile/authentication/overview/HouseAthlete'));
const PlaceDetails = lazy(() => import('../../container/profile/authentication/overview/PlaceDetails'));
const PlaceList = lazy(() => import('../../container/profile/authentication/overview/PlaceList'));
const MyMap = lazy(() => import('../../container/profile/authentication/overview/MyMap'));

const Users = () => {
  const { path } = useRouteMatch();

  useEffect(() => {
    // Function to update meta tags based on the current route
    const updateMetaTags = () => {
      const { pathname } = window.location;
      if (pathname === '/about-us') {
        document.title = 'About Page';
        document.querySelector('meta[name="description"]').setAttribute('content', 'About Page Description');
      } else if (pathname === '/find-housing-for-athletes') {
        document.title = 'CrowdServe| Player';
        document.querySelector('meta[name="og:description"]').setAttribute('content', 'With CrowdServe, you gain access to a network of gracious hosts near tournament locations.');
        document.querySelector('meta[name="og:keywords"]').setAttribute('content', 'With CrowdServe, you gain access to a network of gracious hosts near tournament locations.');
        document.querySelector('meta[property="og:image"]').setAttribute('content', 'https://www.crowdserve.co/static/media/Player_Image.c3b67ca7a045c80e01e4.png');
        document.querySelector('meta[name="description"]').setAttribute('content', 'With CrowdServe, you gain access to a network of gracious hosts near tournament locations.');
        document.querySelector('meta[name="keywords"]').setAttribute('content', 'With CrowdServe, you gain access to a network of gracious hosts near tournament locations.');
      } else if (pathname === '/host-athletes') {
        document.title = 'CrowdServe| Host';
        document.querySelector('meta[name="og:description"]').setAttribute('content', 'Be the game-changer by offering housing to athletes, amplifying their chances of success. Together, we turn accommodations into connections and memories.');
        document.querySelector('meta[name="og:keywords"]').setAttribute('content', 'Be the game-changer by offering housing to athletes, amplifying their chances of success. Together, we turn accommodations into connections and memories.');
        document.querySelector('meta[property="og:image"]').setAttribute('content', 'https://www.crowdserve.co/static/media/hostimg.8206a30230330ea2e601.jpg');
        document.querySelector('meta[name="description"]').setAttribute('content', 'Be the game-changer by offering housing to athletes, amplifying their chances of success. Together, we turn accommodations into connections and memories.');
        document.querySelector('meta[name="keywords"]').setAttribute('content', 'Be the game-changer by offering housing to athletes, amplifying their chances of success. Together, we turn accommodations into connections and memories.');
      }
    };

    // Call the function once when the component mounts
    updateMetaTags();
  }, []); // Empty dependency array means this effect runs only once, similar to componentDidMount


  const NotFound = () => {

    return <Redirect to="/" />;
  };

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >

        {/* <Route exact path={`/`} component={HomePageContent} /> */}
        <Route exact path={`/home`} component={Home} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/find-housing-for-athletes" component={Host} />
        <Route exact path="/host-athletes" component={houseAthlete} />
        <Route exact path="/PlaceDetails/:url" component={PlaceDetails} />
        <Route exact path="/placeList" component={PlaceList} />
        <Route exact path="/map-listings" component={MyMap} />
        {/* <Route path={`${path}`} component={Settings} /> */}
      </Suspense>
    </Switch>
  );
};

//export default withAdminLayout(Users);
//export default Users;
export default AuthLayout(Users);
