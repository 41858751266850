import React, { lazy, Suspense,useEffect } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';

// const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));
const SignUp = lazy(() => import('../container/profile/authentication/overview/Signup'));
const FbLogin = lazy(() => import('../container/profile/authentication/overview/FbSignIn'));
const FbSignUp = lazy(() => import('../container/profile/authentication/overview/FbSignup'));
const Host = lazy(() => import('../container/profile/authentication/overview/Host'));
const PlaceDetails = lazy(() => import('../container/profile/authentication/overview/PlaceDetails'));
const Home = lazy(() => import('../container/profile/authentication/overview/Home'));
const PlayerPage = lazy(() => import('../container/profile/authentication/overview/Player'));
const AboutUsPage = lazy(() => import('../container/profile/authentication/overview/AboutUs'));
const supportAthlete = lazy(() => import('../container/profile/authentication/overview/Support_athlete'));
const houseAthlete = lazy(() => import('../container/profile/authentication/overview/HouseAthlete'));
const ForgotPass = lazy(() => import('../container/profile/authentication/overview/ForgotPassword'));
const PlaceList = lazy(() => import('../container/profile/authentication/overview/PlaceList'));
const AdminLogin = lazy(() => import('../container/profile/authentication/overview/AdminLogin'));
const MyMap = lazy(() => import('../container/profile/authentication/overview/MyMap'));

function NotFound() {
  return <Redirect to="/" />;
}


function FrontendRoutes() {
  useEffect(() => {
    // Function to update meta tags based on the current route
    const updateMetaTags = () => {
      const { pathname } = window.location;
      if (pathname === '/about-us') {
        document.title = 'About Page';
        document.querySelector('meta[name="description"]').setAttribute('content', 'About Page Description');
      } else if (pathname === '/find-housing-for-athletes') {
        document.title = 'CrowdServe| Player';
        document.querySelector('meta[name="og:description"]').setAttribute('content', 'With CrowdServe, you gain access to a network of gracious hosts near tournament locations.');
        document.querySelector('meta[name="og:keywords"]').setAttribute('content', 'With CrowdServe, you gain access to a network of gracious hosts near tournament locations.');
        document.querySelector('meta[property="og:image"]').setAttribute('content', 'https://www.crowdserve.co/static/media/Player_Image.c3b67ca7a045c80e01e4.png');
        document.querySelector('meta[name="description"]').setAttribute('content', 'With CrowdServe, you gain access to a network of gracious hosts near tournament locations.');
        document.querySelector('meta[name="keywords"]').setAttribute('content', 'With CrowdServe, you gain access to a network of gracious hosts near tournament locations.');
      } else if (pathname === '/host-athletes') {
        document.title = 'CrowdServe| Host';
        document.querySelector('meta[name="og:description"]').setAttribute('content', 'Be the game-changer by offering housing to athletes, amplifying their chances of success. Together, we turn accommodations into connections and memories.');
        document.querySelector('meta[name="og:keywords"]').setAttribute('content', 'Be the game-changer by offering housing to athletes, amplifying their chances of success. Together, we turn accommodations into connections and memories.');
        document.querySelector('meta[property="og:image"]').setAttribute('content', 'https://www.crowdserve.co/static/media/hostimg.8206a30230330ea2e601.jpg');
        document.querySelector('meta[name="description"]').setAttribute('content', 'Be the game-changer by offering housing to athletes, amplifying their chances of success. Together, we turn accommodations into connections and memories.');
        document.querySelector('meta[name="keywords"]').setAttribute('content', 'Be the game-changer by offering housing to athletes, amplifying their chances of success. Together, we turn accommodations into connections and memories.');
      }
    };
    // Call the function once when the component mounts
    updateMetaTags();
  }, []); // Empty dependency array means this effect runs only once, similar to componentDidMount

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path="/forgotPassword" component={ForgotPass} />
        <Route exact path="/register" component={SignUp} />
        {/* <Route exact path="/" component={Login} /> */}
        <Route exact path="/" component={Home} />
        <Route exact path="/fbRegister" component={FbSignUp} />
        <Route exact path="/find-housing-for-athletes" component={Host} />
        <Route exact path="/PlaceDetails/:url" component={PlaceDetails} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/placeList" component={PlaceList} />
        <Route exact path="/i8eyri&-admin-login-q9836" component={AdminLogin} />
        <Route exact path="/supportAthlete" component={supportAthlete} />
        <Route exact path="/host-athletes" component={houseAthlete} />
        <Route exact path="/player" component={PlayerPage} />
        <Route exact path="/map-listings" component={MyMap} />
        <Route exact path="/about-us" component={AboutUsPage} />
        <Route exact path="/fbSignIn" component={FbLogin} />
        {/* <Route exact path="*" component={NotFound} /> */}
      </Suspense>
    </Switch>
  );
}

export default AuthLayout(FrontendRoutes);
