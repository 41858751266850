import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
//import CampaigningSMSTable from './../campaigning/CampaigningSMSTable';
//import CampaigningEmailTable from './../campaigning/CampaigningEmailTable';
import HostTable from './HostTable';
import PlayerTable from './PlayerTable';
import { Button } from '../../../src/components/buttons/buttons';
import Heading from '../../../src/components/heading/heading';
import { PageHeader } from '../../../src/components/page-headers/page-headers';
import { Main, CardToolbox } from '../../../src/container/styled';
import FeatherIcon from 'feather-icons-react';


function WaitListTable() {

  const [type, setType] = useState(true);
  const [campaigning_data, setCampaigningData] = useState(null);


  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={type != true ? "Player Waitlist" : "Host Waitlist"}
          buttons={[
            <Button style={{fontFamily: "'Urbanist', sans-serif", fontWeight: '600'}} type="primary" size="default" outlined={!type} onClick={() => setType(true)}>Host Waitlist</Button>,
            <Button style={{fontFamily: "'Urbanist', sans-serif", fontWeight: '600'}}  type="primary" size="default" outlined={type} onClick={() => setType(false)} >Player Waitlist</Button>,
          ]}
        />
      </CardToolbox>

      <Main>
        <Row gutter={15}>
          <Col md={24}>
            {type != true && <PlayerTable />}
            {type == true && <HostTable />}
          </Col>
        </Row>
      </Main>
    </>
  );
}

WaitListTable.propTypes = {
  match: PropTypes.object,
};
export default WaitListTable;
