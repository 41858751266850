import React from 'react';
import { Row, Col } from 'antd';
// import { Aside, Content } from './overview/style';
// import Heading from '../../../components/heading/heading';


const AuthLayout = (WraperContent) => {
  return function () {
    return (
      <Row style={{overflowX:'hidden'}}>
        {/* <Col xxl={8} xl={9} lg={12} md={8} xs={24}>
          <Aside>
            <div className="auth-side-content">
              <img src={require('../../../static/img/auth/topShape.png')} alt="" className="topShape" />
              <img src={require('../../../static/img/auth/bottomShape.png')} alt="" className="bottomShape" />
            </div>
          </Aside>
        </Col> */}

        <Col style={{minHeight:800}} className='main' xxl={24} xl={24} lg={24} md={24} xs={24}>
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
