import React, { useState, useEffect } from 'react';
import { Avatar } from 'antd';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import Message from './message';
import Notification from './notification';
import Settings from './settings';
import Support from './support';
import { Popover } from '../../popup/popup';
import { Dropdown } from '../../dropdown/dropdown';
import { logOut } from '../../../redux/authentication/actionCreator';
import { fbAuthLogout } from '../../../redux/firebase/auth/actionCreator';
import Heading from '../../heading/heading';
import axios from 'axios';

function AuthInfo() {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();


  const { isAuthenticate, userRecord } = useSelector((state) => {
    //console.log("state.fb.auth. :",state.fb.auth);    
    return {
      isAuthenticate: state.fb.auth.uid,
    };
  });

  const [state, setState] = useState({
    flag: 'english',
  });

  const [profile_name, setProfileName] = useState('')

  const { flag } = state;
  /*
  const SignOut = e => {
    e.preventDefault();
    if (isAuthenticate) {
      dispatch(fbAuthLogout(dispatch(logOut())));
    } else {
      dispatch(logOut());
    }
  };
  */

  useEffect(() => {
    if (isAuthenticate != undefined) {
      axios.get(`${process.env.REACT_APP_API_URL}/get-user-details/${isAuthenticate}`)
        .then((response) => {

          if (response.data[0]) {


            setProfileName(response.data[0].first_name + " " + response.data[0].last_name);
            //setProfileIAm(response.data[0].i_am);
            //sessionStorage.setItem('full_name', response.data[0].first_name+" "+response.data[0].last_name);
            //let taste_test_status = response.data[0].taste_test_status == 1 ? true : false;
            //sessionStorage.setItem('taste_test_status', taste_test_status);
            //sessionStorage.setItem('taste_test_status_visible', response.data[0].taste_test_status);
            //sessionStorage.setItem('user_address', response.data[0].address);
            // if (response.data[0].logo != null) {
            //   var image = JSON.parse(response.data[0].logo);
            //   setProfileImage(image[0].url);
            // }
          }
        }).catch((error) => {
          console.log("Error", error)
        })
    }

  }, []);

  const SignOut = async (e) => {
    e.preventDefault();

    // Disable the button to prevent multiple clicks
    e.target.disabled = true;

    // Perform the logout process
    try {
      dispatch(fbAuthLogout(dispatch(logOut())));
      localStorage.clear();
      sessionStorage.clear();
      history.push("/");
      location.reload();
      //window.location.href = '/';
    } catch (error) {
      console.error("Logout error:", error);
      // Handle any errors that occur during logout
    } finally {
      // Re-enable the button
      e.target.disabled = false;
    }
  };
  const change_URL = (url) => {
    //history.push(url);
    window.location.href = url;
  }

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
          <figcaption>
            <Heading as="h5">{profile_name}</Heading>
            {/* <p>UI Expert</p> */}
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to={`${path}/profile/myProfile`} >
              <FeatherIcon icon="user" /> Profile
            </Link>
          </li>
          {/* <li>
            <Link to="#">
              <FeatherIcon icon="settings" /> Settings
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="dollar-sign" /> Billing
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="users" /> Activity
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="bell" /> Help
            </Link>
          </li> */}
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = value => {
    setState({
      ...state,
      flag: value,
    });
  };

  const country = (
    <NavAuth>
      <Link onClick={() => onFlagChangeHandle('english')} to="#">
        <img src={require('../../../static/img/flag/english.png')} alt="" />
        <span>English</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('germany')} to="#">
        <img src={require('../../../static/img/flag/germany.png')} alt="" />
        <span>Germany</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('spain')} to="#">
        <img src={require('../../../static/img/flag/spain.png')} alt="" />
        <span>Spain</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('turky')} to="#">
        <img src={require('../../../static/img/flag/turky.png')} alt="" />
        <span>Turky</span>
      </Link>
    </NavAuth>
  );

  return (
    <InfoWraper>
      {/* <Message />
      <Notification />

      <Settings />
      <Support /> */}
      {/* <div className="nav-author">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="head-example">
            <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
          </Link>
        </Dropdown>
      </div> */}

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
}

export default AuthInfo;
