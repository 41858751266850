import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table, Popover,Modal } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { UserTableStyleWrapper } from '../../../src/container/pages/style';
import { TableWrapper } from '../../../src/container/styled';
import { useHistory } from 'react-router-dom';
import Heading from '../../../src/components/heading/heading';
import { Button } from '../../../src/components/buttons/buttons';
import { Cards } from '../../../src/components/cards/frame/cards-frame';
import axios from 'axios';


function HostTable() {

  const { isAuthenticate } = useSelector(state => {
    //console.log('state.fb.auth.uid',state.fb.auth.uid);
    return {
      isAuthenticate: state.fb.auth.uid
    };
  });
  const history = useHistory();
  const usersTableData = [];
  const [campaigning, setCampaigning] = useState([]);

  useEffect(() => {

    if (isAuthenticate) {
      axios.get(`${process.env.REACT_APP_API_URL}/get-host-waitlist`)
        .then((response) => {
          setCampaigning(response.data);
        })
        .catch((error) => {
          console.log("Error", error)
        })

    }

  }, []);


  const onDelete = (host_waitlist_id) => {
    //console.log("property_id : ", property);
    Modal.confirm({
      title: 'Do you want to delete these host waitlist?',
      okText: 'Confirm',
      onOk() {

        let newObj = {
          host_waitlist_id: host_waitlist_id          
        };

        axios.post(`${process.env.REACT_APP_API_URL}/delete-host-waitlist`, newObj)
          .then((response) => {
            // if (response.data.affectedRows == 1) {
            //   location.reload();
            // }
            if (response.status == 200) {
              alert(response.data.message);
              location.reload();
            }
          })
          .catch((error) => {
            console.log("Error :", error)
          })
      },
      onCancel() {

      }
    });

  }


  const copy_sms = (message, image_path,other_mobile) => {
    var path = image_path!= null ? image_path : '';
    
    sessionStorage.removeItem("html_json");
    sessionStorage.removeItem("subject");
    sessionStorage.removeItem("other_mobile");
    
    sessionStorage.setItem("message", message);
    sessionStorage.setItem("image_path", path);
    sessionStorage.setItem("other_mobile", other_mobile);
    sessionStorage.setItem("type", "sms");

    history.push('email-campaigning');
  }



  if (campaigning.length > 0 && campaigning != null) {
    campaigning.map((val) => {
      const { host_waitlist_id, first_name, last_name, phone_number, email, type_housing_available, number_of_rooms_available, language_spoken, tennis_like, about_yourself, status, created_at, updated_at  } = val;
      const date = new Date(created_at);
      const options = { 
        year: "numeric", 
        month: "long", 
        day: "numeric", 
        hour: "numeric", 
        minute: "numeric", 
        second: "numeric", 
        timeZone: "UTC" 
      };
      
      const humanReadableDate = date.toLocaleString("en-US", options);
      return usersTableData.push({
        key: host_waitlist_id,
        first_name: first_name,
        last_name: last_name,
        phone_number: phone_number,
        email:email,
        type_housing_available:type_housing_available,
        number_of_rooms_available:number_of_rooms_available,
        created_at: (<p><FeatherIcon icon="calendar" size={14} /> {humanReadableDate}</p>),
        language_spoken:language_spoken, 
        tennis_like:tennis_like, 
        about_yourself:about_yourself, 
        status:status,
        action: (
          <div className="table-actions">
            <>
              <Button className="btn-icon" type="danger" shape="circle" onClick={() => onDelete(host_waitlist_id)}>
                <FeatherIcon icon="trash-2" size={16} />
              </Button>
            </>
          </div>
        )
      });
    });
  }


  const usersTableColumns = [
    {
      title: 'First Name',
      align: 'center',
      dataIndex: 'first_name',
      key: 'nafirst_nameme',
    },    
    {
      title: 'Last Name',
      align: 'center',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'Phone',
      align: 'center',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: 'Email',
      align: 'center',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Type of Housing ',
      align: 'center',
      dataIndex: 'type_housing_available',
      key: 'type_housing_available',
    },
    {
      title: 'Rooms',
      align: 'center',
      dataIndex: 'number_of_rooms_available',
      key: 'number_of_rooms_available',
    },
    {
      title: 'Languages Spoken',
      align: 'center',
      dataIndex: 'language_spoken',
      key: 'language_spoken',
    },
    {
      title: 'Player/Fan',
      align: 'center',
      dataIndex: 'tennis_like',
      key: 'tennis_like'
    },
    // {
    //   title: 'About Yourself',
    //   align: 'center',
    //   dataIndex: 'about_yourself',
    //   key: 'about_yourself'
    // },
    {
      title: 'Entry Date',
      align: 'center',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '90px',
    }
  ];

  const rowSelection = {
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <Cards headless>
      <UserTableStyleWrapper>
        <TableWrapper className="table-responsive">
          <Table
            // rowSelection={rowSelection}
            dataSource={usersTableData}
            columns={usersTableColumns}
            pagination={{
              defaultPageSize: 5,
              total: usersTableData.length,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </TableWrapper>
      </UserTableStyleWrapper>
    </Cards>
  );
}


export default HostTable;
